.save-and-exit-modal .modal-wrapper {
    height: 140px!important;
    width: 500px!important;
    border-radius: 10px;
}

.modal-content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end; 
}

.x-button {
    width: 30px;
    height: 30px;
    margin-right: 4px;
}

.save-and-exit-text {
    width: 75%;
    align-self: center;
}

.return-button {
    width: max-content;
}