
* {
  font-family: 'Open Sans', sans-serif;
}
ion-split-pane {
    margin-top: 4rem; /* may need to do this conditionally */
}

ion-thumbnail {
    --size: 4rem;
}

.ios ion-toolbar {
    background-color: #1A1B3F;
}

form ion-item {
   border-radius: 10px;
   border: 1px solid #dedede;
} 

.successItem {
  border-radius: 10px;
  border: 1px solid green;
} 

.MuiPickersToolbar-toolbar {
  background-color: var(--ion-color-secondary) !important;
}

.MuiPickersDay-daySelected {
  background-color: var(--ion-color-primary) !important;
}

.MuiButton-textPrimary {
  color: var(--ion-color-primary) !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: solid 2px var(--ion-color-primary) !important;
}
.date-width {
  width: 100% !important;
}

.danger{
    border: 2px solid var(--ion-color-danger, red);
    background-color: rgba(255, 0, 7, 0.16);
}

.text-light {
  --color: var(--ion-color-light); 
  --background: var(--ion-color-secondary);
}

.android-fit-content {
    height: fit-content(130%);
    font-size: 88%;
    overflow-y: scroll;
}

ion-select {
    min-width: 100%;
    padding-left: 0;
}

.well {
    min-height: 20px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }


  .action-sheet-group {
      position: relative; 
      bottom: 10rem;
      border-radius: 5px;
  }

  .long-select .action-sheet-group {
    position: relative;
    bottom:1rem;
  }

  .states-select .action-sheet-group {
    position: relative;
    top: 15px;
  }

  .sales-rep-select .action-sheet-group {
    position: relative;
    bottom: 3rem;
  }

  .page {
    position: absolute;
    left: 15px;
    right: 15px;
  }
  
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms, transform 500ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
  }

  .MuiAutocomplete-root {
    min-width: 100%;
  }

  .circle-list {
    list-style-type: circle;
  }

  .input-mask {
    width: 100%; 
    height: 100%;
    border: none;
    border-radius: 10px;
  }

  .input-mask:focus {
    border: none; 
    outline: none; 
  }

  .warning-icon {
    color: var(--ion-color-warning-shade);
    font-size: 40px;
  }

  .warning-text {
    position: relative;
    bottom: 5px;
    font-size: 24px;
  }

  @media (prefers-color-scheme: dark) {
    .input-mask {
      background-color: white;
      color: var(--ion-color-light-contrast);
    }
  }

  .error-page-contact {
    text-decoration: none !important;
  }

  .error-page-contact:hover {
    color: var(--ion-color-secondary);
  }

  .warning-message {
    min-height: 20px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 5px solid var(--ion-color-warning-shade);;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }  

.text-left {
  text-align: left!important;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 10px;
}

.pt-1 {
  padding-top: 10px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pl-3 {
  padding-left: 30px;
}

.pr-3 {
  padding-right: 30px;
}

.mr-1 {
  margin-right: 10px;
}

.feeSchedule {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  margin: 10px;
  padding: 0px;

}

.standardFeeScheduleRow {
  border: solid black;
  border-width: 0px 0px 1px 0px;
  margin: 0px;
  padding: 5px;
}

.smallFeeScheduleRow {
  border: solid black;
  border-width: 1px 0px 0px 0px;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
}

.feeSectionHeader {
  background-color: #1A1B3F;
  color: white;
  padding: 0px 5px;
}

.leftBorder {
  border: solid black;
  border-width: 0px 0px 0px 1px;
}

.feeScheduleLink {
  text-decoration: none !important;
}

.feeScheduleLink:hover {
  color: var(--ion-color-secondary);
}

.noDecorationDivider {
  background-color: transparent;
  border: none;
}

.ion-button-list-spacing {
  display: grid;
  max-width: 500px;
}

.ion-button-list-spacing ion-button {
  padding-top: 13px;
}

a.download-link ion-button {
  width: 100%;
}

ion-row.home-mailing-differ{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: nowrap;
}

ion-row.home-mailing-differ>ion-checkbox{
    margin-left: 5px;
    margin-right: 10px;
}

ion-row.home-mailing-differ>div{
  width: 90%;
}