.my-custom-class .modal-wrapper {
  background: #222;
  height: 120px !important;
  width: 360px;
  border-radius: 10px;
}

.timeout-grid {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}