.force-margin-left {
    margin-left: var(--ion-margin,16px) !important;
}

.force-margin-right {
    margin-right: var(--ion-margin,16px) !important;
}

.logo {
    margin: 10px;
    height: 45px;
    width: auto;
}

.logo-mobile {
    margin-top: 2px;
    height: 50px;
    width: auto;
}