.modal-wrapper {
    height: 480px!important;
    top: 30%!important;
}
.p-0 {
    padding: 0!important;
  }
.p-1 {
    padding: 10px;
}
.p-2 {
    padding: 20px;
}
.p-3 {
    padding: 30px;
}
.p-4 {
    padding: 40px;
}
.p-5 {
    padding: 50px;
}
.pl-0 {
    padding-left: 0;
}
.pl-1 {
    padding-left: 10px!important;
}
.pl-2 {
    padding-left: 20px;
}
.pl-3 {
    padding-left: 30px;
}
.pl-4 {
    padding-left: 40px;
}
.pl-5 {
    padding-left: 50px;
}
.pt-0 {
    padding-top: 0;
}
.pt-1 {
    padding-top: 10px;
}
.pt-2 {
    padding-top: 20px;
}
.pt-3 {
    padding-top: 30px;
}
.pt-4 {
    padding-top: 40px;
}
.pt-5 {
    padding-top: 50px;
}
.pr-0 {
    padding-right: 0;
}
.pr-1 {
    padding-right: 10px!important;
}
.pr-2 {
    padding-right: 20px;
}
.pr-3 {
    padding-right: 30px;
}
.pr-4 {
    padding-right: 40px;
}
.pr-5 {
    padding-right: 50px;
}
.pb-0 {
    padding-bottom: 0;
}
.pb-1 {
    padding-bottom: 10px;
}
.pb-2 {
    padding-bottom: 20px;
}
.pb-3 {
    padding-bottom: 30px;
}
.pb-4 {
    padding-bottom: 40px;
}
.pb-5 {
    padding-bottom: 50px;
}

@media only screen and (max-width: 800px) {
    .modal-wrapper {
        max-height: unset!important;
    }
}