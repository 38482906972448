.checkout { 
  background-color: white; 
  border: 1px solid var(--ion-color-primary); 
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.checkout-desktop {
  z-index: 100;
  position: static;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}